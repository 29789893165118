import React from 'react';
import { Button, Card } from 'react-bootstrap';

class Headline extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    //
  }

  saveForLater= (article)=>{  
    alert("article has been added to favorite list");
    var favObj = {};
    //if we already have a favorite list
    if(localStorage.getItem("favObj")){
    favObj = JSON.parse(localStorage.getItem("favObj"));
    }
    favObj[new Date().getTime()] = article;
    localStorage.setItem("favObj", JSON.stringify(favObj));
  }

  getRandomInt= (max) => {
    return Math.floor(Math.random() * max);
  }

  render() {
    let the_img = this.props.article.image;
    if (!the_img) {
      //the_img = 'https://place-hold.it/300x200';
      the_img = 'https://u.plnia.com/img/single-default-' + this.getRandomInt(5) + '.jpg';
    }
    return (
    <Card style={{ width: '95%', margin: '0 2.5%' }}>
      <Card.Img variant="top" style={{ width: '100%', objectFit: 'cover', height: '200px' }} src={the_img} />
      <Card.Body className="text-start">
        <Card.Title><a href={this.props.article.link} target="_blank" rel="noopener" className="article-headline">{this.props.article.title}</a></Card.Title>
        <Card.Subtitle>{this.props.article.source}</Card.Subtitle>
        <Card.Text>
        </Card.Text>
        <a href={this.props.article.link} target="_blank" rel="noopener" className="d-inline-block me-2 btn btn-dark btn-sm text-uppercase">
          Read More
        </a>
      </Card.Body>
    </Card>
    );
  }

}

export default Headline;
