import React from 'react';
import {Button, Container, Row, Col, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Headline from './Headline';

class Favorites extends React.Component {

  constructor(props){
    super(props);
    this.state={
        favoriteList: []
    }
  }

  componentDidMount(){
    this.getFavoriteList();
  }

  getFavoriteList = ()=>{

      var favObj = {};
      var favoriteList = [];

      if(localStorage.getItem("favObj")){
        favObj = JSON.parse(localStorage.getItem("favObj"));
      }

      Object.keys(favObj).forEach((key)=>{

        favoriteList.push(favObj[key])
      });

      this.setState({
        favoriteList:favoriteList
      })


  }

  getArticle = (article,index)=>{

     return (
         <Col key={index} className="col-12 col-md-6 col-xl-4 d-flex align-items-stretch pb-5">
         <Headline article={article}></Headline>
         </Col>
     )
  }
  
  render(){  
      
    if ( ! this.state.favoriteList.length ) {
      return (
        <Container className="pt-5 pb-5">
          <Row>
            <Col className="col-12">
              <h1 className="pb-5">Saved Articles</h1>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 pb-5">No articles saved.</Col>
          </Row>
        </Container>
      );
    } else {
      return (
              <Container className="pt-5 pb-5">
                <Row>
                  <Col className="col-12">
                    <h1 className="pb-5">Saved Articles</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  {this.state.favoriteList.map(this.getArticle)}
                  </Col>
                </Row>
              </Container>
    
      );  
    }


   }
}

export default Favorites;
