import React from 'react';
import Headline from './Headline';
import Secondary from './Secondary';
import {Container, Row, Col} from 'react-bootstrap';

class CatNews extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      headlines: [],
      second: [],
      //third: [],
      error: null,
      isLoaded: false,
      whichLoad: 1,
      isLoaded2: false,
      whichLoad2: 1,
      isLoaded3: false,
      whichLoad3: 1
    }
  }

  componentDidMount() {
    this.getHeadlines();
    this.refreshHeadlines();
    this.getSecond();
    this.refreshSecond();
    //this.getThird();
    this.refreshThird();
  }

  componentWillUnmount = () => {
    clearInterval( this.refreshHeadlines() );
  }

  refreshHeadlines = () => {
    setInterval( () => {
      console.log( 'refresh_' + this.state.whichLoad )
      this.getHeadlines();
      this.setState({
        whichLoad: this.state.whichLoad + 1
      });
    }, 1860000);
    //31 minutes
  }

  getHeadlines () {
    fetch("https://u.plnia.com/c/agg_" + this.props.category + "_built.json")
    .then( res => res.json() )
    .then(
      (result) => {
        console.log(result);
        this.setState({
          headlines: Object.values( result ),
          isLoaded: true
        });
      },
      (error) => {
        this.setState({
          error: error,
          isLoaded2: true
        })
      }
    )
  }

  refreshSecond = () => {
    setInterval( () => {
      console.log( 'refresh2_' + this.state.whichLoad2 )
      this.getSecond();
      this.setState({
        whichLoad2: this.state.whichLoad2 + 1
      });
    }, 1860000);
    //31 minutes
  }

  getSecond () {
    fetch("https://u.plnia.com/b/" + this.props.category + "_built.json")
    .then( res => res.json() )
    .then(
      (result) => {
        console.log(result);
        this.setState({
          second: Object.values( result ),
          isLoaded2: true
        });
      },
      (error) => {
        this.setState({
          error: error,
          isLoaded2: true
        })
      }
    )
  }

  getHeadline = (article, index) => {
    if ( (article.title) && (article.link) ) {
      return (
        <Col key={index} className="col-12 col-md-6 col-xl-4 d-flex align-items-stretch pb-5">
          <Headline article={article}></Headline>
        </Col>
      );
    }
  }

  refreshThird = () => {
    setInterval( () => {
      console.log( 'refresh3' + this.state.whichLoad3 )
      this.getThird();
      this.setState({
        whichLoad3: this.state.whichLoad3 + 1
      });
    }, 1860000);
    //31 minutes
  }

  // getThird () {
  //   fetch("https://u.plnia.com/t/tsch_" + this.props.category + "_built.json")
  //   .then( res => res.json() )
  //   .then(
  //     (result) => {
  //       console.log(result);
  //       this.setState({
  //         third: Object.values( result ),
  //         isLoaded3: true
  //       });
  //     },
  //     (error) => {
  //       this.setState({
  //         error: error,
  //         isLoaded3: true
  //       })
  //     }
  //   )
  // }

  getSecondary = (article, index) => {
    if ( (article.title) && (article.link) ) {
      return (
        <Secondary key={index} article={article}></Secondary>
      );
    }
  }

  render() {

    const {error, isLoaded, headlines} = this.state;

    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div>Loading ...</div>
    } else {
      return (
          <Container className="pt-5 pb-5">
            <Row>
              <Col className="col-12 pb-5">
                <h1>{this.props.catTitle}</h1>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-lg-12">
                <Row>
                  {this.state.headlines.slice(0,7).map(this.getHeadline)}
                  {this.state.second.slice(0,7).map(this.getHeadline)}
                </Row>
              </Col>
            </Row>
          </Container>
      );
    }
  }

}

export default CatNews;
