import React from 'react';
import {Row, Col} from 'react-bootstrap';

class Secondary extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    //
  }

  saveForLater= (article)=>{  
    alert("article has been added to favorite list");
    var favObj = {};
    //if we already have a favorite list
    if(localStorage.getItem("favObj")){
    favObj = JSON.parse(localStorage.getItem("favObj"));
    }
    favObj[new Date().getTime()] = article;
    localStorage.setItem("favObj", JSON.stringify(favObj));
  }

  getRandomInt= (max) => {
    return Math.floor(Math.random() * max);
  }

  render() {
    return (
    <Row>
      <Col className="col-12">
        <a href={this.props.article.link} target="_blank" rel="noopener" className="secondary-article text-start d-inline-block m-2 mb-3">
          {this.props.article.title}
        </a>
        <hr />
      </Col>
    </Row>
    );
  }

}

export default Secondary;
