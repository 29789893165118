import React from 'react';
import './App.css';
import Navv from './components/Navv';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import CatNews from './components/CatNews';
import Favorites from './components/Favorites';

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //
  }

  render() {

    return (
      <div className="App">
        <Navv />
        <Router>
          <Routes>
            <Route path="/" element={<Home catTitle={'Trending'} />} />
            <Route path="/astronomy" element={<CatNews category={'astronomy'} catTitle={'Astronomy'} />} />
            <Route path="/business" element={<CatNews category={'business'} catTitle={'Business'} />} />
            <Route path="/economy" element={<CatNews category={'economy'} catTitle={'Economy'} />} />
            <Route path="/entertainment" element={<CatNews category={'entertainment'} catTitle={'Entertainment'} />} />
            <Route path="/environment" element={<CatNews category={'environment'} catTitle={'Environment'} />} />
            <Route path="/food" element={<CatNews category={'food'} catTitle={'Food'} />} />
            <Route path="/health" element={<CatNews category={'health'} catTitle={'Health'} />} />
            <Route path="/movies" element={<CatNews category={'movies'} catTitle={'Movies'} />} />
            <Route path="/music" element={<CatNews category={'music'} catTitle={'Music'} />} />
            <Route path="/politics" element={<CatNews category={'politics'} catTitle={'Politics'} />} />
            <Route path="/science" element={<CatNews category={'science'} catTitle={'Science'} />} />
            <Route path="/sports" element={<CatNews category={'sports'} catTitle={'Sports'} />} />
            <Route path="/technology" element={<CatNews category={'technology'} catTitle={'Technology'} />} />

            <Route path="/saved" element={<Favorites />} />
          </Routes>
        </Router>
      </div>
    );
  
  }

}

export default App;
