import React from 'react';
import logo from './../logo.png';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';

class Navv extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    //
  }

  render() {
    return (
      <Navbar bg="dark" className="navbar-dark" expand="lg">
        <Container>
          <Navbar.Brand href="/"><img src={logo} style={{width:90, height: 'auto', marginTop: -8}} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Trending</Nav.Link>
              <Nav.Link href="/astronomy">Astronomy</Nav.Link>
              <Nav.Link href="/economy">Economy</Nav.Link>
              <Nav.Link href="/entertainment">Entertainment</Nav.Link>
              <Nav.Link href="/environment">Environment</Nav.Link>
              <Nav.Link href="/food">Food</Nav.Link>
              <Nav.Link href="/health">Health</Nav.Link>
              <Nav.Link href="/movies">Movies</Nav.Link>
              <Nav.Link href="/music">Music</Nav.Link>
              <Nav.Link href="/science">Science</Nav.Link>
              <Nav.Link href="/sports">Sports</Nav.Link>
              <Nav.Link href="/technology">Tech</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

}

export default Navv;
